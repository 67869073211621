import { Injectable } from '@angular/core';
import { AlertType } from '@app/core/constants/message.constant';
import { Subject } from 'rxjs';

export interface Alert {
  message: string | null;
  showDescription: boolean;
  description: string;
  type: AlertType;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertSubject = new Subject<Alert>();
  private hideAlertSubject = new Subject<void>();

  getAlertObservable() {
    return this.alertSubject.asObservable();
  }

  getHideAlertObservable() {
    return this.hideAlertSubject.asObservable();
  }

  success(message: string | null, showDescription = false, description = '') {
    this.showAlert(message, showDescription, description, 'success');
  }

  error(message: string | null, showDescription = false, description = '') {
    this.showAlert(message, showDescription, description, 'danger');
  }

  warning(message: string | null, showDescription = false, description = '') {
    this.showAlert(message, showDescription, description, 'warning');
  }

  info(message: string | null, showDescription = false, description = '') {
    this.showAlert(message, showDescription, description, 'info');
  }

  showAlert(
    message: string | null,
    showDescription = false,
    description = '',
    type: AlertType = 'success',
  ) {
    const alert: Alert = { message, showDescription, description, type };
    this.alertSubject.next(alert);
  }

  clearAlert() {
    this.hideAlertSubject.next();
  }
}
