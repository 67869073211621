import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertService } from './core/services/alert.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'dac-master-records-frontend';

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) {
    console.log('is production', environment.production);
    // Subscribe to NavigationStart event to hide the alert on route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clearAlert();
      }
    });
  }
}
