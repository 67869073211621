import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { canActivate } from '@app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    // canActivate: [canActivate],
  },
  {
    path: '**',
    loadComponent: () =>
      import('@app/shared/components/pagenotfound/pagenotfound.component').then(
        (c) => c.PagenotfoundComponent,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
